<template>
    <AddClinic/>
</template>
<script>
import AddClinic from "@/components/admin/modules/gest-clinic/AddClinic";

export default {
    name: "AddClinicView",
    title: "Añadir Clínica | Baja California Health Tourism",
    components: {
        AddClinic
    }
}
</script>